import React,
{
    DetailedHTMLProps,
    HTMLAttributes
} from 'react';

import * as classNames from "@css/component/InfoBalloon.module.scss";
import clsx from "clsx";

/**
 * @type InfoBalloonHTMLProps
 */
type InfoBalloonHTMLProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
    variant?: string;
};

const InfoBalloon = (props: InfoBalloonHTMLProps) => {
    const {
        children,
        className,
        variant,
    } = props;

    return (
        <div className={ clsx(classNames.InfoBalloon, {[ classNames.bottom ]: variant == "bottom", [ classNames.large ]: variant == "large"}, className ) }>
            <p className={ classNames.paragraph }>{ children }</p>
            <span className={ classNames.triangle } />
        </div>
    );
}

export default InfoBalloon;